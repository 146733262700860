import React, { useState } from "react";
import { animated, useSpring } from "@react-spring/web";
import { usePortfolioContext } from "../../../context";
import { colours } from "../../../styles/colours";
import {
  Bangle,
  Character,
  Eyes,
  Hair,
  Head,
  Lines,
  Secondary,
  Shading,
  Skin,
  SkinAlt,
  Teeth,
  Text,
} from "./component.styled";

const WoahYouGoBigGuy = () => {
  const { minimalMode } = usePortfolioContext();

  const [reverse, setReverse] = useState(false);

  const [rotateTextSpring] = useSpring(() => ({
    from: { rotate: 360 },
    to: { rotate: 0 },
    loop: true,
    config: {
      duration: 7500,
    },
  }));

  const armRotation = 3.5;

  const [rotateLeftArmSpring] = useSpring(() => ({
    from: { rotate: -armRotation },
    to: [{ rotate: armRotation }, { rotate: -armRotation }],
    loop: true,
    config: {
      duration: 750,
    },
  }));

  const [rotateRightArmSpring] = useSpring(() => ({
    from: { rotate: armRotation },
    to: [{ rotate: -armRotation }, { rotate: armRotation }],
    loop: true,
    config: {
      duration: 750,
    },
  }));

  const scale = 1;
  const maxScale = 1.1;

  const [scaleSpring] = useSpring(() => ({
    from: { scale },
    to: [{ scale: maxScale }, { scale }],
    loop: true,
    reverse,
    config: {
      duration: 750,
    },
    onResolve: () => {
      setReverse(!reverse);
    },
  }));

  return (
    <svg viewBox="0 0 1500 1500">
      <Text
        transform={rotateTextSpring.rotate.to(
          (value) => `rotate(${value} 750  750)`
        )}
        isAnimated
        minimalMode={minimalMode}
      >
        <Character
          minimalMode={minimalMode}
          d="M99.82,947.5l217.32-53.46l16.61,34.73l-89.78,69.77l111.05-25.31l16.61,34.73l-178.02,135.63l-17.56-36.72
                l90.2-70.94l-111.46,26.48l-16.14-33.74l90.67-69.95l-111.93,25.49L99.82,947.5z"
        />
        <Character
          minimalMode={minimalMode}
          d="M294.81,743.39l8.08,100.44l-218.85,17.6l-8.08-100.44L294.81,743.39z M258.2,787.61l-139.03,11.18
                l1.57,19.52l139.03-11.18L258.2,787.61z"
        />
        <Character
          minimalMode={minimalMode}
          d="M311.1,629.68l-7.07,40.3l-88.19-15.47l-2.93,16.69l88.19,15.47l-7.11,40.52L77.73,689.26l17.11-97.51
                L311.1,629.68z M174.48,647.04l-46.37-8.13l-3,17.12l46.37,8.13L174.48,647.04z"
        />
        <Character
          minimalMode={minimalMode}
          d="M363.1,508.27l-17.02,36l-78.96-37.34l-16.46,34.8l78.96,37.34l-17.21,36.39l-198.48-93.87l16.93-35.8
                L212,524.17l16.55-35l-81.14-38.38l17.21-36.39L363.1,508.27z"
        />
        <Character
          minimalMode={minimalMode}
          d="M533.08,249.99l-24.99,12.99l41.8,80.42l-36.5,18.98l-41.5-79.84l-26.35,13.7l-59.76-114.97l36.5-18.98
                l48.4,93.11l15.03-7.81l-48.4-93.11l36.31-18.87L533.08,249.99z"
        />
        <Character
          minimalMode={minimalMode}
          d="M688.79,298.84l-98.08,23.05l-50.23-213.73l98.08-23.05L688.79,298.84z M639.59,269.28L607.68,133.5
                l-19.06,4.48l31.91,135.78L639.59,269.28z"
        />
        <Character
          minimalMode={minimalMode}
          d="M802.98,298.07l-98.53-2.56l5.7-219.49l40.25,1.05l-4.68,179.9l18.03,0.47l4.68-179.9l40.25,1.05
                L802.98,298.07z"
        />
        <Character
          minimalMode={minimalMode}
          d="M1058.47,415.16l-79.41-60.23l132.69-174.94l79.41,60.23l-24.6,32.43l-47.33-35.9l-84.03,110.78l15.25,11.57
                l30.71-40.49l32.08,24.33L1058.47,415.16z"
        />
        <Character
          minimalMode={minimalMode}
          d="M1132.85,503.83l-62.21-79.26l172.7-135.57l62.21,79.26L1132.85,503.83z M1138.94,446.75l109.71-86.12
                l-12.09-15.4l-109.71,86.12L1138.94,446.75z"
        />
        <Character
          minimalMode={minimalMode}
          d="M1204.21,792.82l-2.58-119.65l219.5-4.74l2.15,99.63l-87.1,1.88c-0.75,6.18-0.6,12.78,0.43,20.02
                L1204.21,792.82z M1244.45,750.81l48.39-1.05l-0.83-38.49l-48.39,1.04L1244.45,750.81z M1335.07,728.82l45.09-0.97l-0.4-18.48
                l-45.09,0.97L1335.07,728.82z"
        />
        <Character
          minimalMode={minimalMode}
          d="M1192.31,848.98l7.38-37.79l215.5,42.09l-7.38,37.79L1192.31,848.98z"
        />
        <Character
          minimalMode={minimalMode}
          d="M1153.06,961.46l37.67-92.26l203.26,82.99l-37.67,92.26l-37.68-15.38l22.45-54.99l-128.72-52.56l-7.23,17.72
                l47.05,19.21l-15.22,37.27L1153.06,961.46z"
        />
        <Character
          minimalMode={minimalMode}
          d="M953.73,1156.94l84.54-52.77l116.25,186.26l-84.54,52.77l-21.55-34.53l50.39-31.45l-73.62-117.95
                l-16.24,10.13l26.91,43.11l-34.15,21.32L953.73,1156.94z"
        />
        <Character
          minimalMode={minimalMode}
          d="M846.32,1194.81l93.98-29.71l66.18,209.35l-38.39,12.13L913.85,1215l-17.2,5.44l54.24,171.59l-38.39,12.13
                L846.32,1194.81z"
        />
        <Character
          minimalMode={minimalMode}
          d="M735.56,1295.22l28.13-1.4l-4.51-90.53l41.09-2.05l4.48,89.87l29.66-1.48l6.45,129.42l-41.09,2.05
                l-5.22-104.81l-16.92,0.84l5.22,104.81l-40.87,2.04L735.56,1295.22z"
        />
        <Character
          minimalMode={minimalMode}
          d="M662.95,1255.41l37.86,5.64l-23.57,158.19l-37.86-5.64L662.95,1255.41z M670.65,1196.28l38.95,5.8
                l-5.97,40.04l-38.95-5.8L670.65,1196.28z"
        />
      </Text>
      <animated.g
        style={{ transformOrigin: "center" }}
        transform={scaleSpring.scale.to(
          (value) =>
            `
            scale(${value} ${value})
            `
        )}
      >
        <Skin
          minimalMode={minimalMode}
          d="M1116.79,877.14c-0.02-0.1-0.03-0.19-0.04-0.29l-2.96-43.43c-0.02-0.28-0.09-0.55-0.21-0.81
                    l-21.7-46.35c-0.08-0.18-0.19-0.34-0.31-0.49L1061.76,750l-7.22-8.13c-0.49-0.55-0.68-1.3-0.52-2.01l6.67-29.52
                    c0.05-0.23,0.07-0.46,0.05-0.7l-2.93-34.22c-0.02-0.29-0.1-0.57-0.23-0.82l-15.61-31.23c-0.13-0.25-0.3-0.48-0.51-0.67
                    l-39.22-35.3c-0.3-0.27-0.67-0.46-1.07-0.54l-48.17-9.83c-0.15-0.03-0.3-0.05-0.45-0.05H941.3c-0.93,0-1.77-0.57-2.11-1.43
                    l-9.37-23.42c-0.04-0.1-0.09-0.2-0.14-0.29l-10.71-18.5c-0.14-0.24-0.32-0.45-0.53-0.63l-15.55-12.63
                    c-0.08-0.07-0.17-0.13-0.26-0.18L882.76,528h-0.86H619.76h-0.86l-19.86,11.92c-0.09,0.06-0.18,0.12-0.26,0.18l-15.55,12.63
                    c-0.21,0.17-0.4,0.39-0.53,0.63l-10.71,18.5c-0.05,0.09-0.1,0.19-0.14,0.29l-9.37,23.42c-0.35,0.86-1.18,1.43-2.11,1.43h-11.23
                    c-0.15,0-0.31,0.02-0.45,0.05l-48.17,9.83c-0.4,0.08-0.77,0.27-1.07,0.54l-39.22,35.3c-0.21,0.19-0.39,0.42-0.51,0.67
                    l-15.61,31.23c-0.13,0.26-0.21,0.54-0.23,0.82l-2.93,34.22c-0.02,0.23,0,0.47,0.05,0.7l6.67,29.52c0.16,0.71-0.03,1.46-0.52,2.01
                    L439.9,750l-29.81,35.77c-0.12,0.15-0.23,0.32-0.31,0.49l-21.7,46.35c-0.12,0.25-0.19,0.53-0.21,0.81l-2.96,43.43
                    c-0.01,0.1-0.02,0.2-0.04,0.29l-0.89,4.43c-0.06,0.28-0.16,0.54-0.32,0.78l4.09,6.59L385.9,889l2,5l7.14,1.1L518.61,962H646.9
                    l51,15l50.91-27.41l3.39,0.52L801.9,974l39.67-10.13l7.33,1.13l142.82-5l122.18-81v-1.79L1116.79,877.14z"
        />
        <Lines
          minimalMode={minimalMode}
          d="M646.28,604.4c-12.87,11.88-23.13,17.98-30.93,20.97c-4.49,1.72-2.54,8.49,2.18,7.59
                c8.74-1.68,20.41-7.4,35.46-21.29c33.48-30.9,33.81-48.75,28.91-58c-1.86-3.51-7.23-2.25-7.41,1.72
                C673.98,565.79,669,583.42,646.28,604.4z"
        />
        <Lines
          minimalMode={minimalMode}
          d="M855.07,603.84c15.19,14.02,26.75,20,34.93,22.3c3.97,1.11,3.11,6.9-1.01,6.82
                c-9.12-0.18-22.38-5.02-40.62-21.85c-35.77-33.01-33.7-51.13-27.85-59.78c1.9-2.8,6.28-1.39,6.3,2
                C826.91,563.48,831.21,581.82,855.07,603.84z"
        />

        <Shading
          minimalMode={minimalMode}
          d="M615,630l2.59,1.1c14.76,6.24,25.77,19,29.79,34.51L648,668l48.51-22.91L667,603L615,630z"
        />
        <Shading
          minimalMode={minimalMode}
          d="M595.99,675.28l-25.75,45.14c-4.78,7.65-10.94,14.35-18.15,19.76l-30.42,20.93L552,781l13-12l25-43
                    L595.99,675.28z"
        />
        <Shading
          minimalMode={minimalMode}
          d="M452.96,751.52l-12,25.48c-8.55,18.15-14.64,37.35-18.13,57.08c-1,5.64-1.47,11.36-1.42,17.08l0.35,42.88
                    l-38.1,14.57l3.29-69.34l7.91-27.23l20.31-33.96l21.1-23.21l12.13-11.34L452.96,751.52z"
        />
        <Shading
          minimalMode={minimalMode}
          d="M673.9,930.22l53.31-14.98c14.78-4.15,30.4-4.26,45.23-0.3l57.33,15.28l-70.2-30.08L751.08,887l-16.79,10.55
                    l-20.84,16.62L673.9,930.22z"
        />
        <Shading
          minimalMode={minimalMode}
          d="M575,847.18L618.22,860c13.16,3.9,27.04,4.75,40.59,2.48l76.51-12.86c9.87-1.66,19.95-1.66,29.82-0.01
                    l76.87,12.86c13.51,2.26,27.35,1.41,40.48-2.48l43.27-12.82v-30.59l-20.87,19.32l-24,9.16l-51.13,3.69l-42.73-6.23l-35.96-38.61
                    l-17.58,27.16l-37.98,15.56l-71.63,1.32l-52.33-25.58L575,847.18z"
        />
        <Shading
          minimalMode={minimalMode}
          d="M884.42,630.14l-2.59,1.1c-14.76,6.24-25.77,19-29.79,34.51l-0.62,2.4l-48.51-22.91l29.51-42.09
                    L884.42,630.14z"
        />
        <Shading
          minimalMode={minimalMode}
          d="M903.44,675.42l25.75,45.14c4.78,7.65,10.94,14.35,18.15,19.76l30.42,20.93l-30.34,19.88l-13-12l-25-43
                    L903.44,675.42z"
        />
        <Shading
          minimalMode={minimalMode}
          d="M1046.46,751.65l12,25.48c5.09,10.82,12.63,33.46,12.63,33.46s6.97,33.01,7,49.88l0.05,32.53l38.64,26.11
                    l-4.32-79.71l-7.91-27.23l-20.31-33.96l-21.1-23.21l-12.13-11.34L1046.46,751.65z"
        />
        <Lines
          minimalMode={minimalMode}
          d="M464.06,837.26c1.88-46.96,19.87-51.25,19.87-51.25s-27.5-5.11-29.74,50.85
                    c-3.01,74.94,32.5,61.35,32.5,61.35S462.06,887.22,464.06,837.26z"
        />
        <Lines
          minimalMode={minimalMode}
          d="M446.43,720.47c-2.31-18.59-0.17-38,7.83-55.06c6.89-15.07,18.11-27.8,31.03-38.02
                    c14.61-11.53,31.94-19.4,50.03-23.6c12.4-2.87,25.15-4.2,37.88-3.92c1.96,0.04,2.31-2.78,0.4-3.21c-1.8-0.41-3.6-0.78-5.41-1.12
                    c-0.93-0.18-1.53-1.11-1.28-2.02c3.93-14.25,11.52-27.49,21.86-38.05c8.99-9.25,19.2-17.22,29.82-24.5c0.44-0.3,0.7-0.8,0.7-1.34
                    c-0.01-2.54,0.02-5.08-0.03-7.61h-0.12c-12.52,7.34-24.61,15.63-35.01,25.81c-12.61,12.23-20.68,28.36-25.99,44.94
                    c-0.23,0.72-0.92,1.18-1.68,1.12c-15.71-1.3-31.77,0.24-46.64,5.62c-16.92,5.99-31.9,16.81-43.68,30.28
                    c-11.59,13.23-20.96,28.69-25.79,45.69c-4.36,15.15-4.75,31.39-1.2,46.74c1.17,5.23,2.81,10.34,4.76,15.32
                    c0.23,0.59,0.1,1.26-0.34,1.71c-13.46,13.97-26.06,28.84-36.97,44.91c-5.12,7.64-9.84,15.6-13.49,24.06
                    c-7.66,17.69-11.71,36.91-12.16,56.17c-0.04,1.84-0.04,3.68,0,5.51c0,0.01-0.01,0.02-0.01,0.03c0,25.28,9.2,53.03,15.69,69.48
                    c0.73,1.85,3.5,1.03,3.11-0.92c-4.44-21.98-11.97-60.49-12.05-69.11c0-0.06,0-0.11,0.01-0.17c1.25-16.36,4.56-32.58,9.92-48.09
                    c3.35-9.58,7.59-18.87,13.05-27.44c8.58-13.53,19.18-25.66,30.57-36.88c2.19-2.12,4.37-4.24,6.64-6.25
                    c0.77-0.68,1.98-0.46,2.49,0.43c0.03,0.05,0.06,0.11,0.09,0.16c0.99,1.72,3.6,0.5,2.93-1.37
                    C450,740.3,447.67,730.45,446.43,720.47z"
        />
        <Lines
          minimalMode={minimalMode}
          d="M955.25,769.31c-0.28,0.13-0.59,0.19-0.9,0.13c-3.32-0.57-6.06-2.88-8.57-5.01
                c-6.67-5.99-11.66-13.53-16.57-20.95c-6.22-9.57-11.54-19.74-15.56-30.44c-4.54-12.23-7.89-24.9-10.08-37.77
                c-1.97,0.08-1.35,0.06-3.32,0.14c-0.71,9.32-0.56,18.7,0.44,28l0.01,0.06c0.22,1.52-1.71,2.36-2.67,1.16
                c-2.42-3.02-4.96-5.95-7.77-8.61c-0.22-0.2-0.44-0.4-0.66-0.61c-1.36-1.24-3.34,0.54-2.23,2.02
                c8.35,11.17,15.46,23.25,21.59,35.77c4.95,10.26,9.15,20.92,11.99,31.97c2.7,10.64,4.19,21.76,2.81,32.72
                c-0.97,7.81-3.56,15.49-8.07,21.98c-4.64,6.77-11.2,12.08-18.44,15.85c-8.85,4.62-18.62,7.21-28.45,8.75
                c-2.73,0.42-5.47,0.76-8.21,1.03c-3.01,0.27-26.88,2.15-54.91-2.52c-14.12-2.35-23.93-9.01-27.5-11.77
                c-0.59-0.48-1.18-0.96-1.75-1.47c-7.27-6.36-12.61-14.68-16.32-23.55c-4.33-10.42-7.14-24.49-8.81-37.41
                c-0.23-1.76-2.77-1.76-2.99,0c-1.67,12.92-4.48,26.99-8.81,37.41c-3.71,8.87-9.05,17.18-16.32,23.55
                c-0.57,0.5-1.16,0.99-1.75,1.47c-3.57,2.76-13.38,9.42-27.5,11.77c-28.03,4.67-51.9,2.79-54.91,2.52
                c-2.74-0.27-5.48-0.61-8.21-1.03c-9.83-1.54-19.6-4.13-28.45-8.75c-7.25-3.77-13.81-9.08-18.44-15.85
                c-4.51-6.49-7.1-14.18-8.07-21.98c-1.37-10.96,0.11-22.08,2.81-32.72c2.83-11.05,7.04-21.71,11.99-31.97
                c6.12-12.52,13.24-24.6,21.59-35.77c1.1-1.47-0.88-3.26-2.23-2.02c-0.22,0.2-0.44,0.4-0.66,0.61c-2.81,2.66-5.35,5.59-7.77,8.61
                c-0.96,1.2-2.89,0.37-2.67-1.16l0.01-0.06c1-9.29,1.14-18.68,0.44-28c-1.97-0.08-1.35-0.06-3.32-0.14
                c-2.19,12.86-5.55,25.54-10.08,37.77c-4.02,10.69-9.34,20.86-15.56,30.44c-4.91,7.43-9.9,14.97-16.57,20.95
                c-2.51,2.13-5.25,4.44-8.57,5.01c-0.3,0.05-0.62,0-0.9-0.13c-3.62-1.72-7.16-3.67-10.94-5.05c-3.39-1.27-6.84-2.48-10.35-3.42
                c-1.68-0.45-2.67,1.83-1.2,2.75c0.16,0.1,0.32,0.2,0.48,0.3c13.28,8.63,25.41,19.51,33.79,33.06
                c5.24,8.39,8.33,17.91,10.34,27.54c2.75,13.22,3.45,26.78,3.18,40.25c-0.1,3.84-0.5,9.1-0.95,14.18
                c-0.24,2.63-0.82,5.88-1.55,9.26c-2.79,12.84-9,24.68-17.85,34.39c-10.99,12.05-24.76,26.99-20.45,21.38
                c3.87-5.03,11.56-1.16,10.25,5.05c-0.82,3.9,12.88-12.63,23.08-25.33c8.22-10.24,13.71-22.43,15.66-35.41
                c0.1-0.65,0.18-1.26,0.24-1.84c0.58-5.21,1.18-10.97,1.46-14.99c0.73-12.43,0.95-24.94-0.15-37.35c-0.12-1.4,1.57-2.19,2.56-1.21
                c1.59,1.58,3.23,3.11,5.05,4.44c6.93,5.39,15.03,9.08,23.37,11.69c12.92,3.97,26.46,5.44,39.93,5.83
                c1.81,0.04,3.62,0.05,5.43,0.06l0.23,0.12c0,0,15.09,1.31,39-1c19.54-1.89,31.93-10.93,38.42-17.36
                c1.63-1.56,3.12-3.26,4.48-5.07c0.4-0.53,0.69-0.95,0.87-1.21c1.84-2.64,3.4-5.47,4.63-8.44c0.29-0.71,0.57-1.43,0.84-2.15l0,0
                l2.1-3.92c1.55-2.9,5.72-2.86,7.22,0.06l1.98,3.86l0,0c0.27,0.72,0.54,1.44,0.84,2.15c1.22,2.97,2.79,5.8,4.63,8.44
                c0.18,0.27,0.47,0.68,0.87,1.21c1.36,1.81,2.85,3.51,4.48,5.07c6.49,6.43,18.87,15.47,38.42,17.36c23.91,2.31,39,1,39,1
                l0.23-0.12c1.81-0.01,3.62-0.03,5.43-0.06c13.47-0.38,27.01-1.86,39.93-5.83c8.34-2.6,16.44-6.29,23.37-11.69
                c1.82-1.32,3.45-2.86,5.05-4.44c1-0.99,2.69-0.19,2.56,1.21c-1.1,12.41-0.88,24.92-0.15,37.35c0.23,3.32,0.68,7.83,1.15,12.24
                c0.04,0.34,0.08,0.7,0.12,1.07c2.03,16.74,8.4,32.66,18.28,46.33c6.04,8.34,11.89,16.29,12.18,16.15
                c2.19-1.05,8.68-1.99,10.87-3.03c0.22-0.1-7.36-10.43-14.46-19.99c-9.12-12.3-15.06-26.7-16.97-41.88
                c-0.1-0.76-0.18-1.48-0.24-2.16c-0.5-5.44-0.96-11.25-1.07-15.4c-0.27-13.47,0.43-27.03,3.18-40.25
                c2.01-9.63,5.1-19.15,10.34-27.54c8.38-13.55,20.51-24.43,33.79-33.06c0.16-0.1,0.32-0.2,0.48-0.3c1.47-0.92,0.48-3.21-1.2-2.75
                c-3.51,0.95-6.96,2.15-10.35,3.42C962.42,765.65,958.88,767.59,955.25,769.31z"
        />
        <Lines
          minimalMode={minimalMode}
          d="M810.66,909c-33.49,0-49.43-16.33-56.31-27.11c-2.14-3.36-7.01-3.36-9.15,0
                    C738.33,892.67,722.39,909,688.9,909c-16.27,0-27.51-3.24-35.25-7.47c-5.45-2.98-10.79,4.03-6.48,8.51
                    c7.41,7.69,20.09,13.96,41.73,13.96c33.85,0,49.37-10.8,56.2-22.65c2.08-3.61,7.27-3.61,9.36,0
                    c6.83,11.85,22.35,22.65,56.2,22.65c21.63,0,34.31-6.27,41.73-13.96c4.31-4.48-1.03-11.49-6.48-8.51
                    C838.17,905.76,826.94,909,810.66,909z"
        />
        <Lines
          minimalMode={minimalMode}
          d="M1015.64,786.01c0,0,17.98,4.28,19.87,51.25c2,49.96-22.62,60.96-22.62,60.96s35.51,13.59,32.5-61.35
                    C1043.13,780.91,1015.64,786.01,1015.64,786.01z"
        />
        <Lines
          minimalMode={minimalMode}
          d="M810.66,967c-34.35,0-50.24-17.17-56.82-27.92c-1.85-3.02-6.28-3.02-8.13,0
                    C739.14,949.83,723.25,967,688.9,967c-18.41,0-30.37-4.14-38.12-9.18c-4.49-2.92-9.62,2.68-6.39,6.96
                    c6.86,9.1,19.89,17.22,44.51,17.22c34.57,0,50.02-11.26,56.63-23.4c1.86-3.41,6.65-3.41,8.51,0c6.61,12.14,22.05,23.4,56.63,23.4
                    c24.62,0,37.65-8.12,44.51-17.22c3.23-4.28-1.9-9.88-6.39-6.96C841.04,962.86,829.07,967,810.66,967z"
        />
        <Lines
          minimalMode={minimalMode}
          d="M1118.63,864.38c-0.45-19.26-4.5-38.48-12.16-56.17c-3.65-8.46-8.37-16.42-13.49-24.06
                    c-10.91-16.07-23.51-30.94-36.97-44.91c-0.44-0.46-0.57-1.12-0.34-1.71c1.95-4.98,3.59-10.1,4.76-15.32
                    c3.55-15.36,3.16-31.59-1.2-46.74c-4.83-16.99-14.2-32.45-25.79-45.69c-11.78-13.47-26.76-24.29-43.68-30.28
                    c-14.87-5.39-30.93-6.93-46.64-5.62c-0.76,0.06-1.45-0.4-1.68-1.12c-5.31-16.58-13.38-32.7-25.99-44.94
                    c-10.4-10.18-22.49-18.47-35.01-25.81h-0.12c-0.05,2.54-0.02,5.08-0.03,7.61c0,0.54,0.26,1.04,0.7,1.34
                    c10.62,7.29,20.83,15.26,29.82,24.5c10.34,10.57,17.93,23.81,21.86,38.05c0.25,0.92-0.35,1.85-1.28,2.02
                    c-1.81,0.34-3.62,0.71-5.41,1.12c-1.91,0.44-1.56,3.25,0.4,3.21c12.72-0.28,25.48,1.05,37.88,3.92
                    c18.09,4.19,35.42,12.07,50.03,23.6c12.92,10.22,24.14,22.95,31.03,38.02c8,17.06,10.14,36.47,7.83,55.06
                    c-1.24,9.98-3.57,19.83-6.94,29.32c-0.66,1.87,1.95,3.08,2.93,1.37c0.03-0.05,0.06-0.11,0.09-0.16c0.51-0.89,1.72-1.11,2.49-0.43
                    c2.27,2.02,4.45,4.14,6.64,6.25c11.39,11.22,21.99,23.35,30.57,36.88c5.45,8.57,9.7,17.86,13.05,27.44
                    c5.36,15.51,8.67,31.73,9.92,48.09c0,0.06,0.01,0.11,0.01,0.17c-0.09,8.62-7.62,47.13-12.05,69.11
                    c-0.39,1.95,2.38,2.77,3.11,0.92c6.49-16.45,15.69-44.21,15.69-69.48c0-0.01-0.01-0.02-0.01-0.03
                    C1118.67,868.06,1118.67,866.22,1118.63,864.38z"
        />
        <Lines
          minimalMode={minimalMode}
          d="M750.9,653c50.6,0,85.54,10.64,103.56,17.94c4.64,1.88,8.28-4.28,4.39-7.45
                C846.19,653.18,817.2,641,750.9,641c-61.76,0-91.23,12.41-105.22,23.42c-4.03,3.17-0.16,9.49,4.52,7.4
                C668.46,663.63,701.67,653,750.9,653z"
        />
        <animated.g
          transform={rotateLeftArmSpring.rotate.to(
            (value) => `rotate(${value} 750  750)`
          )}
        >
          <SkinAlt
            minimalMode={minimalMode}
            points="382.9,880 412.9,859 441.9,850 485.9,853 515.9,860 551.9,877 581.9,904 594.9,924 602.9,949 
                        602.9,962 598.9,987 584.9,996 559.9,1009 515.9,1015 473.9,1009 419.9,991 387.9,967 372.9,944 369.9,916 374.9,891	"
          />
          <SkinAlt
            minimalMode={minimalMode}
            points="623.9,923 635.9,905 655.9,888 670.9,877 678.9,866 685.9,853 688.9,830 698.9,830 705.9,838 
                        708.67,847.47 705.9,865 700.9,882 695.9,901 737.94,909.98 743.9,919 743.9,932 740.9,938 745.9,954 737.9,966 740.9,984 
                        733.9,1000 737.9,1015 733.9,1026 722.9,1031 688.9,1031 649.9,1028 634.9,1015 625.9,1003 638.9,999 646.9,984 646.9,958 
                        646.9,941 638.9,926	"
          />
          <Bangle
            minimalMode={minimalMode}
            points="638.9,924 594.9,924 602.9,949 602.9,968 589.9,992.66 587.9,1001 637.11,999.78 645.9,981 
                        649.9,956 645.9,934	"
          />
          <Shading
            minimalMode={minimalMode}
            d="M392.23,874.08l10.98,42.63c2.88,11.18,9.43,21.07,18.59,28.09l28.18,21.58l40.09,8.44h51.43l14.41-3.45
                c2.86-0.69,5.69-1.49,8.49-2.42l34.86-11.56c1.04-0.34,2.1,0.43,2.1,1.52v11.17l-11.08,21.1l-25.85,17.94l-39.3,7.91L496.8,1015
                l-30.66-3.52l-26.7-8.31l-25.91-13.05l-16.81-11.27l-13.85-13.05l-11.24-21.56L367.22,924l4.41-27.24l15.39-20.57L392.23,874.08z"
          />
          <Shading
            minimalMode={minimalMode}
            d="M602.9,958.5l10.31,1.96c7.07,1.34,14.32,1.32,21.38-0.06L646.9,958v24l-6.76,17.88l-24.53,1.12l-26.9-1.12
                L600.5,977L602.9,958.5z"
          />
          <Shading
            minimalMode={minimalMode}
            d="M674.64,901.3l6.88-6.15c7-6.25,12.66-13.86,16.64-22.36l4.71-10.05l-4.51,31.64l-4.45,8.31L674.64,901.3z"
          />
          <Shading
            minimalMode={minimalMode}
            d="M651.54,999.63l27.67,5.44c1.3,0.26,2.61,0.43,3.93,0.53l36,2.67c6.63,0,8.22-6.86,11.9-17.36l0.51-3.99
                c1.34-3.81,1.76-7.88,1.25-11.89l-0.5-3.89l-1.34-1.31l3.35-15.49c0.82-3.79,0.78-7.71-0.11-11.48l0,0l-1.64-3.54l2.08-11.09
                c0.68-3.6,0.58-7.31-0.28-10.87l-0.77-3.18l11.57,3.15l-2.47,16.52l-2.24,5.84l3.03,10.29l-3.03,10.78l-3.4,9.2l4.95,6.53
                l-8.6,25.22l5.14,9.4l-5.24,14.04l-31.15,6.63l-46.48-4.22l-24.03-14.17l-0.69-9.69l4.96-2.24
                C640.78,999.22,646.26,998.59,651.54,999.63z"
          />
          <Lines
            minimalMode={minimalMode}
            d="M687.92,826.11c3.35-1.22,7.06-0.53,10.43,0.31c5.32,1.39,9.57,5.68,11.4,10.8
                    c2.92,7.92,2.32,16.6,1.14,24.8c-1.89,12.11-5.64,23.82-9.83,35.3c-0.28,0.77,0.26,1.58,1.08,1.62
                    c9.86,0.45,19.74,1.84,29.17,4.78c5.79,1.95,11.84,4.58,15.48,9.73c2.85,4.1,2.27,9.36,1.46,14.01c-0.57,3.34-1.91,6.5-3.82,9.29
                    c-0.31,0.46-0.28,1.05,0.07,1.48c3.02,3.71,4.22,8.63,4.39,13.33c0.2,4.25-1.21,8.61-4.1,11.78c-0.9,1.03-1.93,1.94-2.95,2.85
                    c-0.48,0.42-0.56,1.16-0.15,1.65c3.99,4.8,5.05,11.47,4.61,17.55c-0.53,5.68-3.85,10.58-7.98,14.34c-0.51,0.47-0.5,1.27,0,1.75
                    c2.34,2.3,3.36,5.69,4.02,8.83c1.23,5.96-0.87,12.56-5.62,16.44c-4.83,4-11.08,5.68-17.17,6.52c-6.55,0.99-13.2,0.66-19.8,0.69
                    c-14.83-0.21-29.76-0.45-44.4-3.13c-8.62-1.7-17.56-4.15-24.31-10.06c-4.19-3.52-6.66-8.68-7.49-14.03
                    c-0.09-0.6-0.59-1.05-1.2-1.05c-13.93-0.01-27.86,0.01-41.79-0.01c-0.71-0.02-1.44,0.04-2.06,0.43
                    c-10.2,5.35-21.38,8.59-32.69,10.56c-27.05,4.54-54.88,2.2-81.51-3.84c-3.91-1.02-7.93-1.69-11.68-3.26
                    c-11.11-4.28-22.04-9.05-32.56-14.63c-11.45-6.11-22.5-13.21-31.86-22.26c-6.98-6.75-12.85-14.77-16.38-23.86
                    c-4.05-10.14-4.76-21.19-4.72-31.99c-0.48-16.09,5.96-32.02,16.63-43.94c10.57-11.88,25.19-19.59,40.47-23.5
                    c22.1-5.72,45.33-4.75,67.72-1.37c3.76,0.66,7.58,1.1,11.25,2.14c0.03,0.01,0.07,0.02,0.1,0.03
                    c25.34,8.83,49.91,21.14,69.95,39.23c9.34,8.45,17.54,18.25,23.71,29.26c0.18,0.32,0.5,0.56,0.87,0.6c0.53,0.06,1.08,0,1.61,0.03
                    c7.33-0.02,14.65,0,21.97-0.01c0.5,0,0.95-0.32,1.13-0.79c3.34-8.89,9.03-16.78,15.77-23.42c5.56-5.53,11.98-10.15,18.85-13.94
                    c7.28-4.13,14.02-9.58,18.42-16.8c3.6-5.72,5.52-12.35,6.33-19.03c0.65-4.5,0.66-9.11,1.74-13.54
                    C684.21,829.44,685.47,826.93,687.92,826.11z M693.4,833.01c-0.27,0.05-0.53,0.19-0.71,0.4c-1.36,1.65-1.49,3.99-1.9,6.02
                    c-1.27,8.66-2.45,17.52-6.22,25.52c-4.13,9.28-11.75,16.8-20.81,21.29c-4.73,2.3-8.58,5.96-12.31,9.59
                    c-6.52,6.71-12.44,13.98-17.97,21.52c-0.59,0.8,0,1.93,0.99,1.93c0.65,0,1.3,0.02,1.93,0.14c0.09,0.02,0.19,0.05,0.28,0.08
                    c2.87,1.11,5.17,3.32,7,5.75c3.84,5.24,5.93,11.53,7.42,17.79c2.7,12.32,3,25.21,0.68,37.61c-1.52,7.48-4,15.19-9.35,20.85
                    c-1.95,1.88-4.29,3.86-7.03,4.22c-0.76,0.1-1.25,0.88-0.98,1.6c1.25,3.26,3.73,5.97,6.52,8.02c5.98,4.44,13.2,6.77,20.36,8.5
                    c13.25,2.96,26.89,3.66,40.43,3.69c7.05-0.08,14.16-0.14,21.08-1.68c4.27-0.95,8.96-2.99,10.67-7.34
                    c1.57-4.29,0.87-8.94-0.05-13.32c-0.17-0.8-1.05-1.21-1.77-0.82c-1.81,0.99-3.61,2.02-5.59,2.55c-1.24,0.23-1.57-1.27-0.76-1.96
                    c4.31-5.27,8.25-11.02,10.35-17.55c1.54-5.21,1.29-10.73,0.56-16.06c-0.11-0.79-0.95-1.26-1.69-0.93
                    c-0.74,0.32-1.49,0.65-2.25,0.72c-1.04,0.1-1.76-1.14-1.07-1.93c0-0.01,0.01-0.01,0.01-0.02c3.42-4.87,6.5-10.1,8.06-15.89
                    c0.93-3.47,0.26-7.09-0.94-10.42c-0.22-0.6-0.88-0.92-1.48-0.73c-0.88,0.28-1.78,0.52-2.68,0.69c-0.89-1.92-0.75-1.61-1.64-3.54
                    c1.05-0.8,2.05-1.67,2.85-2.75c3.66-4.42,5.35-10.3,4.99-15.99c-0.01-0.1-0.02-0.21-0.05-0.31c-0.79-2.81-3.57-4.48-6.01-5.74
                    c-4.41-2.1-9.18-3.31-13.94-4.34c-12.37-2.56-24.98-3.54-37.49-5.18c-2.51-0.4-5.09-0.65-7.45-1.63c-0.86-0.29-1.07-1.3-0.8-2.07
                    c0.09-0.25,0.26-0.47,0.49-0.61c1.12-0.68,2.49-0.89,3.76-1.15c4.24-0.67,8.54-0.88,12.83-0.87c0.51,0,0.97-0.32,1.15-0.81
                    c2.76-7.82,5.38-15.69,7.45-23.73c2.16-8.45,3.97-17.08,4.06-25.83c-0.05-4.16-0.37-8.66-2.88-12.15
                    C699.73,833.61,696.37,832.51,693.4,833.01z M447.28,855.62c-6.13,0.45-12.24,1.23-18.24,2.55c-7.55,1.92-14.92,4.69-21.73,8.48
                    c-8.32,4.5-15.52,11.04-20.9,18.8c-5.12,8.05-9.15,17.19-9.32,26.88c-0.12,8.57-0.08,17.22,1.56,25.66
                    c0.01,0.04,0.02,0.07,0.02,0.11c1.82,6.98,4.96,13.63,9.27,19.43c7.99,10.32,18.62,18.28,29.83,24.78
                    c12.84,7.47,26.48,13.44,40.32,18.77c2.52,1.01,5.19,1.49,7.8,2.16c17.58,3.95,35.6,6.06,53.63,5.85
                    c10.91-0.19,21.84-1.21,32.49-3.59c9.38-2.41,18.55-5.88,26.81-11c4.33-2.65,8.05-6.16,11.45-9.91
                    c2.91-3.34,4.95-7.35,6.52-11.46c1.42-3.52,1.58-7.38,1.66-11.13c-0.05-6.05-0.6-12.12-1.91-18.03
                    c-2.26-8.53-5.87-16.68-10.45-24.21c-4.61-7.56-10.45-14.3-16.84-20.41c-6.37-6.32-13.5-11.84-21.02-16.75
                    c-14.82-9.58-30.96-17.01-47.58-22.91c-1.92-0.71-3.97-0.96-5.97-1.31C479.05,855.83,463.13,854.52,447.28,855.62z
                     M603.76,929.24c-0.85,0-1.43,0.86-1.12,1.66c2.01,5.22,3.5,10.64,4.47,16.15c1.53,9.62,2.23,19.76-0.94,29.14
                    c-2.12,6.61-5.99,12.49-10.73,17.52c-0.73,0.78-0.19,2.05,0.87,2.05c12.45,0.01,24.9,0.01,37.35,0c0.31,0,0.61-0.11,0.84-0.33
                    c3.9-3.66,5.67-8.99,7.17-13.99c0.01-0.04,0.02-0.08,0.03-0.12c2.25-10.89,2.18-22.19,0.48-33.15
                    c-0.58-4.11-1.94-8.06-3.57-11.86c-1.1-2.44-2.3-4.94-4.35-6.72c-0.14-0.12-0.31-0.22-0.5-0.27c-0.65-0.15-1.34-0.06-2-0.09
                    C622.43,929.26,613.09,929.22,603.76,929.24z"
          />
          <Lines
            minimalMode={minimalMode}
            d="M548.4,976.78c-0.05,0.02-0.1,0.04-0.16,0.05c-5.73,1.39-11.38,3.12-17.16,4.3
                c-13.24,2.77-26.91,3.57-40.37,2.08c-13.13-1.39-25.95-5.13-37.99-10.51c-9.32-4.06-18.49-8.58-26.91-14.32
                c-6.86-4.73-13.5-10.42-17.13-18.1c-0.96-1.96-1.34-4.18-1.02-6.33c0.12-0.83,1.1-1.21,1.73-0.65c2.61,2.32,5.23,4.64,8.17,6.54
                c15.39,10.32,32.71,17.32,50.13,23.39c12.42,4.39,25.31,7.51,38.41,8.98c17.58,1.93,35.54,1.44,52.87-2.23
                c1.14-0.24,1.74,1.32,0.73,1.91l0,0c-0.03,0.02-0.06,0.04-0.1,0.05L548.4,976.78z"
          />
        </animated.g>
        <animated.g
          transform={rotateRightArmSpring.rotate.to(
            (value) => `rotate(${value} 750  750)`
          )}
        >
          <SkinAlt
            minimalMode={minimalMode}
            points="1116.9,880 1086.9,859 1057.9,850 1013.9,853 983.9,860 947.9,877 917.9,904 904.9,924 896.9,949 
                    896.9,962 900.9,987 914.9,996 939.9,1009 983.9,1015 1025.9,1009 1079.9,991 1111.9,967 1126.9,944 1129.9,916 1124.9,891
                    "
          />
          <SkinAlt
            minimalMode={minimalMode}
            points="875.9,923 863.9,905 843.9,888 828.9,877 820.9,866 813.9,853 810.9,830 800.9,830 793.9,838 
                    791.13,847.47 793.9,865 798.9,882 803.9,901 761.86,909.98 755.9,919 755.9,932 758.9,938 753.9,954 761.9,966 758.9,984 
                    765.9,1000 761.9,1015 765.9,1026 776.9,1031 810.9,1031 849.9,1028 864.9,1015 873.9,1003 860.9,999 852.9,984 852.9,958 
                    852.9,941 860.9,926"
          />
          <Bangle
            minimalMode={minimalMode}
            points="860.9,924 904.9,924 896.9,949 896.9,968 909.9,992.66 911.9,1001 862.69,999.78 853.9,981 
                        849.9,956 853.9,934	"
          />
          <Shading
            minimalMode={minimalMode}
            d="M1107.19,874.21l-10.98,42.63c-2.88,11.18-9.43,21.07-18.59,28.09l-28.18,21.58l-40.09,8.44h-51.43
                l-14.41-3.45c-2.86-0.69-5.69-1.49-8.49-2.42l-34.86-11.56c-1.04-0.34-2.1,0.43-2.1,1.52v11.17l11.08,21.1l25.85,17.94l39.3,7.91
                l28.35-2.02l30.66-3.52l26.7-8.31l25.91-13.05l16.81-11.27l13.85-13.05l11.24-21.56l4.41-20.23l-4.41-27.24l-15.39-20.57
                L1107.19,874.21z"
          />
          <Shading
            minimalMode={minimalMode}
            d="M896.52,958.64l-10.31,1.96c-7.07,1.34-14.32,1.32-21.38-0.06l-12.32-2.41v24l6.76,17.88l24.53,1.12
                l26.9-1.12l-11.79-22.88L896.52,958.64z"
          />
          <Shading
            minimalMode={minimalMode}
            d="M824.79,901.44l-6.88-6.15c-7-6.25-12.66-13.86-16.64-22.36l-4.71-10.05l4.51,31.64l4.45,8.31L824.79,901.44z
                "
          />
          <Shading
            minimalMode={minimalMode}
            d="M847.88,999.77l-27.67,5.44c-1.3,0.26-2.61,0.43-3.93,0.53l-36,2.67c-6.63,0-8.22-6.86-11.9-17.36l-0.51-3.99
                c-1.34-3.81-1.76-7.88-1.25-11.89l0.5-3.89l1.34-1.31l-3.35-15.49c-0.82-3.79-0.78-7.71,0.11-11.48l0,0l1.64-3.54l-2.08-11.09
                c-0.68-3.6-0.58-7.31,0.28-10.87l0.77-3.18l-11.57,3.15l2.47,16.52l2.24,5.84l-3.03,10.29l3.03,10.78l3.4,9.2l-4.95,6.53
                l8.6,25.22l-5.14,9.4l5.24,14.04l31.15,6.63l46.48-4.22l24.03-14.17l0.69-9.69l-4.96-2.24
                C858.64,999.36,853.17,998.73,847.88,999.77z"
          />
          <Lines
            minimalMode={minimalMode}
            d="M816.35,831.79c1.08,4.43,1.1,9.04,1.74,13.54c0.81,6.67,2.73,13.31,6.33,19.03
                    c4.41,7.21,11.15,12.67,18.42,16.8c6.86,3.79,13.29,8.41,18.85,13.94c6.74,6.64,12.44,14.52,15.77,23.42
                    c0.18,0.47,0.62,0.79,1.13,0.79c7.33,0.01,14.65-0.01,21.97,0.01c0.53-0.03,1.09,0.03,1.61-0.03c0.37-0.04,0.69-0.28,0.87-0.6
                    c6.17-11.01,14.37-20.81,23.71-29.26c20.04-18.08,44.61-30.4,69.95-39.23c0.03-0.01,0.07-0.02,0.1-0.03
                    c3.68-1.03,7.5-1.48,11.25-2.14c22.39-3.39,45.62-4.35,67.72,1.37c15.28,3.91,29.9,11.62,40.47,23.5
                    c10.67,11.93,17.11,27.85,16.63,43.94c0.04,10.8-0.67,21.86-4.72,31.99c-3.52,9.09-9.4,17.11-16.38,23.86
                    c-9.36,9.05-20.41,16.15-31.86,22.26c-10.52,5.58-21.45,10.34-32.56,14.63c-3.74,1.57-7.77,2.24-11.68,3.26
                    c-26.63,6.05-54.46,8.39-81.51,3.84c-11.31-1.97-22.49-5.21-32.69-10.56c-0.61-0.39-1.34-0.45-2.06-0.43
                    c-13.93,0.02-27.86,0-41.79,0.01c-0.61,0-1.1,0.45-1.2,1.05c-0.83,5.35-3.3,10.51-7.49,14.03c-6.76,5.91-15.69,8.37-24.31,10.06
                    c-14.64,2.68-29.57,2.92-44.4,3.13c-6.6-0.02-13.25,0.31-19.8-0.69c-6.09-0.84-12.35-2.53-17.17-6.52
                    c-4.75-3.88-6.85-10.48-5.62-16.44c0.66-3.15,1.68-6.53,4.02-8.83c0.5-0.49,0.51-1.29,0-1.75c-4.13-3.76-7.46-8.66-7.98-14.34
                    c-0.44-6.08,0.62-12.75,4.61-17.55c0.41-0.49,0.33-1.23-0.15-1.65c-1.02-0.91-2.05-1.82-2.95-2.85
                    c-2.88-3.16-4.29-7.52-4.1-11.78c0.17-4.71,1.37-9.62,4.39-13.33c0.35-0.43,0.38-1.02,0.07-1.48c-1.91-2.79-3.25-5.95-3.82-9.29
                    c-0.8-4.65-1.39-9.91,1.46-14.01c3.63-5.15,9.69-7.78,15.48-9.73c9.43-2.94,19.31-4.34,29.17-4.78c0.82-0.04,1.36-0.85,1.08-1.62
                    c-4.19-11.48-7.95-23.19-9.83-35.3c-1.18-8.21-1.78-16.88,1.14-24.8c1.84-5.12,6.08-9.41,11.4-10.8
                    c3.37-0.83,7.07-1.52,10.43-0.31C814.53,826.93,815.79,829.44,816.35,831.79z M798.51,836.15c-2.52,3.49-2.83,7.99-2.88,12.15
                    c0.09,8.75,1.9,17.38,4.06,25.83c2.07,8.03,4.68,15.91,7.45,23.73c0.17,0.49,0.63,0.81,1.15,0.81c4.29-0.01,8.59,0.21,12.83,0.87
                    c1.27,0.27,2.64,0.47,3.76,1.15c0.23,0.14,0.4,0.35,0.49,0.61c0.27,0.78,0.06,1.78-0.8,2.07c-2.36,0.98-4.94,1.23-7.45,1.63
                    c-12.51,1.63-25.12,2.62-37.49,5.18c-4.76,1.03-9.53,2.24-13.94,4.34c-2.44,1.26-5.22,2.93-6.01,5.74
                    c-0.03,0.1-0.04,0.21-0.05,0.31c-0.35,5.69,1.34,11.57,4.99,15.99c0.8,1.08,1.79,1.95,2.85,2.75c-0.89,1.92-0.75,1.61-1.64,3.54
                    c-0.9-0.17-1.8-0.41-2.68-0.69c-0.61-0.19-1.27,0.13-1.48,0.73c-1.2,3.32-1.87,6.94-0.94,10.42c1.55,5.78,4.64,11.02,8.06,15.89
                    c0,0.01,0.01,0.01,0.01,0.02c0.69,0.78-0.03,2.03-1.07,1.93c-0.76-0.08-1.51-0.4-2.25-0.72c-0.73-0.32-1.58,0.14-1.69,0.93
                    c-0.74,5.33-0.99,10.86,0.56,16.06c2.1,6.53,6.04,12.28,10.35,17.55c0.81,0.69,0.48,2.19-0.76,1.96
                    c-1.99-0.53-3.79-1.56-5.59-2.55c-0.71-0.39-1.6,0.02-1.77,0.82c-0.92,4.39-1.62,9.03-0.05,13.32c1.71,4.35,6.4,6.39,10.67,7.34
                    c6.91,1.53,14.03,1.6,21.08,1.68c13.54-0.03,27.18-0.72,40.43-3.69c7.16-1.74,14.38-4.06,20.36-8.5
                    c2.79-2.05,5.27-4.77,6.52-8.02c0.27-0.72-0.22-1.5-0.98-1.6c-2.75-0.35-5.08-2.34-7.03-4.22c-5.36-5.66-7.83-13.37-9.35-20.85
                    c-2.32-12.41-2.02-25.3,0.68-37.61c1.49-6.26,3.58-12.55,7.42-17.79c1.83-2.42,4.13-4.63,7-5.75c0.09-0.03,0.18-0.06,0.28-0.08
                    c0.64-0.12,1.28-0.14,1.93-0.14c1,0,1.58-1.12,0.99-1.93c-5.54-7.54-11.45-14.81-17.97-21.52c-3.73-3.63-7.59-7.29-12.31-9.59
                    c-9.06-4.49-16.68-12.01-20.81-21.29c-3.76-8-4.95-16.86-6.22-25.52c-0.41-2.03-0.54-4.36-1.9-6.02
                    c-0.18-0.21-0.44-0.36-0.71-0.4C803.63,832.51,800.27,833.61,798.51,836.15z M1005.3,858.4c-2.01,0.35-4.05,0.6-5.97,1.31
                    c-16.62,5.89-32.75,13.33-47.58,22.91c-7.51,4.91-14.64,10.43-21.02,16.75c-6.39,6.11-12.22,12.85-16.84,20.41
                    c-4.58,7.54-8.19,15.68-10.45,24.21c-1.32,5.91-1.86,11.98-1.91,18.03c0.08,3.75,0.24,7.61,1.66,11.13
                    c1.56,4.12,3.61,8.13,6.52,11.46c3.4,3.75,7.12,7.26,11.45,9.91c8.26,5.11,17.43,8.58,26.81,11c10.66,2.39,21.59,3.4,32.49,3.59
                    c18.03,0.21,36.05-1.9,53.63-5.85c2.62-0.68,5.29-1.16,7.8-2.16c13.84-5.33,27.49-11.3,40.32-18.77
                    c11.22-6.5,21.84-14.46,29.83-24.78c4.3-5.79,7.44-12.45,9.27-19.43c0.01-0.04,0.02-0.07,0.02-0.11
                    c1.65-8.44,1.68-17.1,1.56-25.66c-0.17-9.69-4.2-18.83-9.32-26.88c-5.38-7.76-12.59-14.3-20.9-18.8
                    c-6.81-3.8-14.18-6.56-21.73-8.48c-6-1.33-12.11-2.11-18.24-2.55C1036.87,854.52,1020.95,855.83,1005.3,858.4z M868.24,929.22
                    c-0.66,0.03-1.35-0.06-2,0.09c-0.18,0.04-0.35,0.14-0.5,0.27c-2.05,1.78-3.25,4.29-4.35,6.72c-1.63,3.8-2.99,7.75-3.57,11.86
                    c-1.7,10.96-1.77,22.26,0.48,33.15c0.01,0.04,0.02,0.08,0.03,0.12c1.5,5.01,3.26,10.33,7.17,13.99c0.23,0.21,0.53,0.32,0.84,0.33
                    c12.45,0.01,24.9,0.01,37.35,0c1.07,0,1.6-1.27,0.87-2.05c-4.74-5.03-8.61-10.92-10.73-17.52c-3.17-9.38-2.47-19.52-0.94-29.14
                    c0.97-5.51,2.45-10.93,4.47-16.15c0.31-0.8-0.27-1.66-1.12-1.66C886.91,929.22,877.57,929.26,868.24,929.22z"
          />
          <Lines
            minimalMode={minimalMode}
            d="M938.95,972.13c-0.68-0.25-1.31-0.75-1.45-1.46c-0.03-0.15-0.13-0.3-0.31-0.39
                c-0.02-0.01-0.05-0.02-0.07-0.04c-0.61-0.3-0.28-1.21,0.38-1.05c18.41,4.38,37.63,5.08,56.41,3.02
                c13.1-1.46,25.99-4.58,38.41-8.98c17.43-6.07,34.75-13.07,50.13-23.39c1.5-0.97,2.91-2.04,4.28-3.16
                c2.44-2.01,6.01,0.68,4.64,3.53c-0.01,0.03-0.02,0.05-0.04,0.08c-3.62,7.68-10.27,13.37-17.13,18.1
                c-8.42,5.74-17.59,10.26-26.91,14.32c-12.04,5.38-24.86,9.12-37.99,10.51c-13.46,1.49-27.13,0.69-40.37-2.08
                c-5.77-1.18-11.4-2.9-17.12-4.29c-0.08-0.02-0.17-0.05-0.25-0.08L938.95,972.13z"
          />
        </animated.g>
        <g>
          <g>
            <Head
              minimalMode={minimalMode}
              {...(minimalMode
                ? { strokeWidth: 10, stroke: colours.vibrant.dark }
                : { strokeWidth: 10, stroke: colours.vibrant.dark })}
              {...(!minimalMode
                ? {
                    transform: rotateLeftArmSpring.rotate.to(
                      (value) => `translate(0 ${value * 2})`
                    ),
                  }
                : null)}
              cx={750.4}
              cy={523}
              rx={129.5}
              ry={172}
            />
            {minimalMode ? (
              <>
                <SkinAlt
                  minimalMode={minimalMode}
                  points="681.9,460 694.9,464 704.9,473 709.9,483 711.9,487 707.9,503 694.9,522 683.9,527 667.9,525 
                    657.9,513 652.9,494 654.9,479 661.9,467 671.9,460"
                />
                <SkinAlt
                  minimalMode={minimalMode}
                  points="818.9,460 805.9,464 795.9,473 790.9,483 788.9,487 792.9,503 805.9,522 816.9,527 832.9,525 
                    842.9,513 847.9,494 845.9,479 838.9,467 828.9,460"
                />
                <Secondary
                  minimalMode={minimalMode}
                  points="624.9,501 634.9,525 644.9,539 653.9,544 669.9,552 673.9,555 671.9,558 657.9,576 646.9,603 
                    641.79,615.52 624.9,561.45 622.9,523"
                />
                <Secondary
                  minimalMode={minimalMode}
                  points="651.81,635.03 659.9,631 669.9,613 675.9,589 752.9,612 741.9,623 735.9,633 732.9,646 730.9,672 
                    732.9,687 737.9,691 730.67,693.32 686.89,673.27 661.57,647.96"
                />
                <Secondary
                  minimalMode={minimalMode}
                  points="669.48,389.76 678.9,396 696.9,410 709.9,425 724.9,441 730.9,446 733.9,441 735.9,416 
                    739.37,380.53 752.9,349 690.9,367 669.48,389.76"
                />
                <Secondary
                  minimalMode={minimalMode}
                  points="875.88,501.45 865.88,525.45 855.88,539.45 846.88,544.45 830.88,552.45 826.88,555.45 
                    828.88,558.45 842.88,576.45 853.88,603.45 858.99,615.96 875.88,561.9 877.88,523.45"
                />
                <Secondary
                  minimalMode={minimalMode}
                  points="848.97,635.48 840.88,631.45 830.88,613.45 824.88,589.45 747.88,612.45 758.88,623.45 
                    764.88,633.45 767.88,646.45 769.88,672.45 767.88,687.45 762.88,691.45 770.11,693.76 813.89,673.72 839.21,648.4"
                />
                <Secondary
                  minimalMode={minimalMode}
                  points="831.29,390.2 821.88,396.45 803.88,410.45 790.88,425.45 775.88,441.45 769.88,446.45 
                    766.88,441.45 764.88,416.45 761.4,380.97 747.88,349.45 809.88,367.45 831.29,390.2"
                />
              </>
            ) : null}
          </g>
          <g>
            {minimalMode ? (
              <>
                <Lines
                  minimalMode={minimalMode}
                  d="M749.87,346h1.59c20.31,0.11,40.36,6.44,57.48,17.27c22.12,13.89,39.51,34.42,51.92,57.25
                    c16.42,30.21,24.38,64.6,25.04,98.87v8.35c-0.81,37.18-10.22,74.57-29.7,106.45c-9.04,14.77-20.25,28.28-33.62,39.32
                    c-13.11,10.84-28.4,19.21-44.88,23.57c-7.89,2.13-16.03,3.23-24.19,3.55h-5.91c-18.9-0.62-37.48-6.43-53.59-16.28
                    c-13.36-8.1-25.12-18.72-35.05-30.76c-11.53-13.97-20.66-29.84-27.51-46.59c-10.31-25.16-15.39-52.3-15.99-79.44v-5.79
                    c0.14-16.86,2.03-33.72,5.84-50.15c6.1-26.26,17.08-51.57,33.36-73.15c10.58-13.99,23.45-26.36,38.39-35.61
                    C710.02,352.3,729.82,346.11,749.87,346z M728.76,359.22c-19.98,4.72-37.94,15.96-52.39,30.37c-0.95,0.95-0.77,2.55,0.4,3.22
                    c7.4,4.26,14.21,9.5,20.55,15.22c3.64,3.51,7.21,7.1,10.73,10.73c4.44,4.94,8.8,9.94,13.11,14.98c2.61,2.86,5.03,5.95,7.99,8.44
                    c1.2,1.01,3.03,0.34,3.33-1.19c0.3-1.51,0.43-3.06,0.58-4.59c0.46-5.82,0.57-11.66,0.81-17.49c0.66-20.02,2.85-40.44,10.52-59.1
                    c0.58-1.42-0.56-2.94-2.08-2.81C737.74,357.43,733.21,358.15,728.76,359.22z M757.98,356.92c-1.52-0.12-2.64,1.4-2.06,2.81
                    c7.9,19.17,9.99,40.2,10.62,60.77c0.25,5.98,0.32,11.97,0.95,17.92c0.08,0.86,0.2,1.72,0.37,2.57c0.31,1.54,2.14,2.18,3.35,1.17
                    c4.08-3.43,7.24-7.85,10.87-11.73c12.22-14.42,25.47-28.64,42.13-37.94c1.17-0.65,1.39-2.27,0.44-3.21
                    c-6.54-6.46-13.73-12.28-21.54-17.14C789.54,363.62,773.98,358.13,757.98,356.92z M739.36,397.8c-1.2,10.72-1.4,21.51-1.84,32.27
                    c-0.27,4.54-0.36,9.15-1.56,13.56c-0.51,1.59-1.22,3.39-2.82,4.14c-1.61,0.7-3.35-0.11-4.68-1.01c-3.37-2.35-6.04-5.52-8.79-8.54
                    c-6.82-7.8-13.44-15.79-20.82-23.06c-7.73-7.63-16.24-14.62-25.83-19.79c-0.83-0.45-1.87-0.26-2.5,0.45
                    c-13.04,14.68-23.01,31.93-30.19,50.17c-0.67,1.71,1.08,3.39,2.75,2.63c3.23-1.48,6.19-3.55,8.87-5.88
                    c5.27-4.6,9.77-10.03,13.67-15.82c0.53-0.78,1.51-1.12,2.4-0.8c5.89,2.16,11.54,4.91,16.99,8c13.41,7.75,25.63,18.25,33.33,31.85
                    c6.78,11.85,9.52,25.9,7.92,39.44c-0.73,6.18-2.77,12.17-5.64,17.68c-1.75,3.55-3.89,6.98-4.81,10.88
                    c-0.77,3.52-0.25,7.67,2.51,10.23c3.17,2.82,7.92,3.46,11.89,2.18c4.73-1.52,8.65-4.9,11.66-8.77c3.3-4.32,5.84-9.55,5.77-15.09
                    c-0.01-49.12,0.01-98.23-0.01-147.35c0-2.38-3.4-2.85-4.02-0.55C741.54,382.21,740.21,390,739.36,397.8z M756.75,374.65
                    c-0.63-2.31-4.02-1.88-4.02,0.52c-0.01,49.12,0.01,98.25-0.01,147.37c-0.04,2.88,0.64,5.75,1.79,8.38
                    c2.72,6.15,7.36,11.68,13.49,14.65c4.02,1.98,9.08,2.03,12.89-0.45c2.44-1.54,3.8-4.39,3.94-7.22c0.35-3.99-1.45-7.71-3.22-11.15
                    c-2.19-4.19-4.39-8.41-5.73-12.97c-2.39-7.69-2.66-15.91-1.75-23.87c1.64-14.6,8.79-28.24,18.99-38.69
                    c10.93-11.2,24.58-19.58,39.2-25.06c0.89-0.33,1.88,0,2.41,0.79c4.09,6.08,8.83,11.75,14.44,16.48c2.83,2.35,5.97,4.42,9.4,5.77
                    c1.67,0.66,3.32-0.99,2.67-2.66c-7.25-18.6-17.4-36.18-30.73-51.07c-0.62-0.7-1.65-0.89-2.47-0.45
                    c-4.87,2.56-9.47,5.6-13.82,8.96c-12.56,9.61-22.78,21.8-33.06,33.71c-2.83,3.12-5.56,6.4-8.99,8.89c-1.41,1-3.27,1.97-5,1.2
                    c-1.6-0.77-2.31-2.57-2.81-4.16c-1.19-4.41-1.28-9.01-1.55-13.54c-0.45-11.03-0.65-22.08-1.93-33.05
                    C760.04,389.48,758.73,381.97,756.75,374.65z M660.09,440.59c-5.89,6.28-13.05,12.07-21.68,13.75c-0.74,0.14-1.36,0.65-1.6,1.37
                    c-4.37,13.11-7.36,26.68-9.06,40.39c-0.38,2.31,0.32,4.58,0.82,6.81c2.77,11.76,7.63,23.43,16.21,32.17
                    c5.77,5.97,13.21,10,20.95,12.82c3.11,1.33,6.68,2.09,9.12,4.57c1.68,1.87,0.32,4.4-0.87,6.13c-3.93,5.72-8.47,11.01-12.33,16.77
                    c-7.74,11.21-13.77,23.9-15.52,37.51c-0.23,0.98,0.44,1.83,0.82,2.69c2.32,4.6,4.79,9.12,7.51,13.51
                    c0.67,1.08,2.15,1.28,3.1,0.44c4.46-3.91,6.64-9.85,8.59-15.33c2.65-7.92,4.72-16.03,7.4-23.95c3.91-11.6,9.29-23.06,18.04-31.83
                    c5.59-5.66,12.44-10,19.86-12.86c1.05-0.4,1.6-1.59,1.17-2.63c-1.05-2.58-1.49-5.42-1.06-8.17c1.02-6.91,5.53-12.52,7.92-18.94
                    c3.63-8.96,3.67-18.95,2.28-28.39c-2.14-13.22-9.24-25.34-18.78-34.61c-9.44-9.48-21.05-16.57-33.34-21.74
                    c-0.89-0.37-1.92-0.05-2.47,0.74C665.01,434.9,662.56,437.76,660.09,440.59z M796.05,454.12c-9.1,9.26-15.75,21.24-17.62,34.18
                    c-1.25,9.29-1.1,19.09,2.56,27.86c2.26,5.93,6.31,11.15,7.57,17.47c0.75,3.08,0.34,6.35-0.84,9.28
                    c-0.42,1.04,0.11,2.23,1.16,2.63c8.32,3.19,15.93,8.29,21.85,14.97c6.67,7.4,11.21,16.45,14.64,25.73
                    c3.8,10.12,6.14,20.72,9.87,30.86c1.83,4.8,4.15,9.88,8.31,13.05c0.97,0.74,2.36,0.51,3-0.52c2.64-4.23,5.06-8.59,7.32-13.03
                    c1.06-1.87,0.16-4.05-0.02-6.02c-1.81-11.05-6.4-21.49-12.34-30.91c-4.35-7.06-9.67-13.43-14.52-20.13
                    c-0.02-0.03-0.05-0.06-0.07-0.1c-1.13-1.79-2.86-3.85-2-6.1c0.95-1.96,3.14-2.81,5.01-3.65c5.48-2.18,11.13-4.08,16.17-7.2
                    c7.59-4.47,13.69-11.22,17.94-18.9c4.63-8.36,7.34-17.64,9.03-27.01c0.59-2.95-0.28-5.9-0.66-8.82
                    c-1.76-10.82-4.35-21.51-7.81-31.91c-0.25-0.74-0.9-1.26-1.66-1.38c-3.68-0.59-7.25-1.84-10.47-3.75
                    c-7.82-4.7-14.03-11.58-19.3-18.95c-0.56-0.78-1.57-1.08-2.46-0.71C817.84,436.47,805.73,444,796.05,454.12z M862.91,532.74
                    c-5.56,7.5-13.29,13.24-21.84,16.89c-3.32,1.56-6.91,2.53-10.22,4.11c-1.14,0.54-1.55,1.95-0.85,3c0.38,0.57,0.77,1.12,1.19,1.67
                    c5.12,6.82,10.45,13.52,14.84,20.85c4.42,7.3,8.05,15.18,10.22,23.46c0.5,1.9,3.16,2.06,3.87,0.23c0.01-0.02,0.02-0.04,0.02-0.06
                    c10.88-26.68,15.66-55.62,15.1-84.37c-0.04-2.22-3.1-2.77-3.94-0.71C869.13,523.11,866.38,528.18,862.91,532.74z M626.35,509.62
                    c0.04,0.89,0.06,1.79,0.02,2.68c-1.57,31.84,3.58,64.15,16.08,93.53c0.08,0.37,0.66,1.5,0.78,0.46
                    c3.15-14.95,11.05-28.38,20.26-40.39c2.24-3.08,4.69-6.02,6.84-9.17c0.7-1.03,0.35-2.46-0.77-2.99
                    c-2.51-1.19-5.21-1.99-7.77-3.07c-8.38-3.26-16.21-8.24-22.06-15.12C633.32,528.12,628.82,519.09,626.35,509.62z M737.45,547.35
                    c-0.82,0.57-1.11,1.67-0.65,2.56c1.56,3.04,4.13,5.58,7.34,6.83c3.76,1.36,7.97,1.38,11.75,0.12c3.32-1.19,5.99-3.76,7.61-6.86
                    c0.48-0.92,0.24-2.05-0.61-2.64c-4.56-3.18-8.35-7.45-10.93-12.37c-0.75-1.43-2.83-1.43-3.58,0
                    C745.8,539.89,742.01,544.16,737.45,547.35z M676.81,603.37c-1.08-1.11-2.97-0.63-3.41,0.86c-1.95,6.64-3.81,13.33-6.77,19.6
                    c-1.79,3.5-3.98,7-7.14,9.4c-0.88,0.67-1.14,1.9-0.51,2.81c7.89,11.48,17.29,21.95,28.2,30.63
                    c11.83,9.49,25.58,16.62,40.26,20.44c1.57,0.41,3.05-1.06,2.53-2.6c-0.52-1.52-0.69-3.2-0.71-4.77
                    c-0.13-10.76,0.16-21.56,1.8-32.21c1.17-7.21,2.71-14.65,6.3-21.07c0.74-1.33-0.17-2.97-1.69-3.03
                    c-13.8-0.55-27.68-2.7-40.46-8.11C688.5,612.43,681.94,608.64,676.81,603.37z M797.76,618.07c-10.66,3.41-21.83,4.91-32.99,5.37
                    c-1.52,0.06-2.42,1.71-1.68,3.03c2.53,4.53,3.98,9.62,5.11,14.67c2.83,13.35,3.16,27.07,2.83,40.67c-0.07,1-0.28,1.99-0.63,2.91
                    c-0.58,1.53,0.86,3.05,2.44,2.65c11.63-2.9,22.81-7.7,32.61-14.63c14.66-9.21,26.7-22.04,36.56-36.18
                    c0.66-0.94,0.38-2.21-0.56-2.87c-2.86-2.03-5.04-4.89-6.74-7.95c-3.64-6.75-5.63-14.2-7.78-21.53c-0.44-1.49-2.32-1.98-3.4-0.87
                    C816.56,610.5,807.17,614.97,797.76,618.07z M747.95,621.78c-0.11,0.02-0.21,0.05-0.32,0.09c-3.42,1.17-5.55,4.36-7.19,7.4
                    c-3.83,7.56-5.14,16.07-6.1,24.41c-0.92,9.35-1.28,18.78-0.85,28.16c0.01,2.44,2.22,4.02,4.42,4.46
                    c5.18,1.22,10.58,1.06,15.87,0.99c3.76-0.24,7.79-0.17,11.18-2.04c1.88-1.15,1.99-3.55,2.03-5.51
                    c0.09-7.52-0.02-15.05-0.67-22.55c-0.81-8.91-1.97-17.97-5.6-26.24c-1.54-3.19-3.37-6.58-6.54-8.4
                    C752.34,621.4,750.02,621.34,747.95,621.78z"
                />
                <Lines
                  minimalMode={minimalMode}
                  d="M674.9,458.12c15.7-2.27,31.97,8.13,37.19,22.99c0.68,2.52,1.56,5.14,1.21,7.78
                  c-1.65,7.92-4.13,15.75-8.16,22.8c-3.33,5.79-7.78,11.16-13.79,14.3c-5.84,3.06-12.87,4.13-19.24,2.21
                  c-6.75-1.67-11.99-6.99-15.14-13c-3.06-5.95-4.59-12.58-5.46-19.18c-0.88-5.86,0.36-11.76,1.9-17.4
                  c2.01-6.85,5.95-13.49,12.2-17.23C668.42,459.63,671.66,458.71,674.9,458.12z M674.9,462.2c-5.62,0.86-10.66,4.33-13.73,9.08
                  c-3.87,5.94-5.53,13.09-5.89,20.11c0.13,5.75,1.21,11.49,3.1,16.93c1.9,5.28,4.87,10.46,9.63,13.64
                  c5.34,3.62,12.45,3.96,18.42,1.81c5.51-1.96,9.91-6.17,13.18-10.91c4.68-6.84,7.47-14.78,9.36-22.8
                  c0.84-2.93-0.07-6.01-1.11-8.77C702.61,468.74,688.56,459.92,674.9,462.2z"
                />
                <Lines
                  minimalMode={minimalMode}
                  d="M816.8,458.08c6.35-0.95,13.14,0.26,18.58,3.73c5.77,3.72,9.46,9.96,11.43,16.42
                  c1.69,5.99,2.98,12.3,1.93,18.53c-1.24,8.6-3.54,17.53-9.31,24.28c-3.18,3.69-7.47,6.48-12.28,7.45
                  c-7.41,1.84-15.52-0.17-21.6-4.67c-3.89-2.75-6.86-6.55-9.39-10.54c-4.52-7.3-7.23-15.57-9.02-23.92
                  c-0.51-2.14,0.13-4.32,0.61-6.4c1.26-5.28,4.37-9.9,7.94-13.9C801.29,463.24,808.77,459.24,816.8,458.08z M816.74,462.18
                  c-9.19,1.47-17.48,7.31-22.3,15.22c-1.98,3.3-3.5,7.06-3.4,10.96c1.36,6.83,3.56,13.53,6.79,19.71
                  c2.73,5.14,6.34,9.99,11.23,13.26c5.42,3.64,12.51,4.8,18.76,2.83c4.5-1.43,8.21-4.73,10.69-8.69
                  c3.76-5.96,5.48-12.96,6.29-19.89c0.66-4.81-0.12-9.68-1.3-14.34c-1.58-5.92-4.51-11.78-9.55-15.48
                  C829.08,462.13,822.64,461.24,816.74,462.18z"
                />
              </>
            ) : null}
          </g>
          <animated.g
            {...(!minimalMode
              ? {
                  transform: rotateLeftArmSpring.rotate.to(
                    (value) => `translate(0 ${value * 2})`
                  ),
                }
              : null)}
          >
            <Eyes
              minimalMode={minimalMode}
              d="M677.36,483.39c4.75-1.38,9.8,0.22,13.97,2.57c4.45,2.53,7.55,7.43,7.35,12.63
                c-4.83-3.59-9.98-7.16-15.97-8.42c-4.42-0.85-9.01-0.25-13.24,1.2C670.89,487.8,673.55,484.48,677.36,483.39z"
            />
            <Eyes
              minimalMode={minimalMode}
              d="M816.27,483.18c3.56-0.76,7.56-0.21,10.41,2.19c1.94,1.55,3.21,3.75,4.17,6c-5.34-1.86-11.36-2.28-16.71-0.22
                c-4.55,1.76-8.63,4.51-12.5,7.43c-0.09-3.79,1.44-7.57,4.18-10.19C808.67,485.63,812.44,484,816.27,483.18z"
            />
            <Teeth
              minimalMode={minimalMode}
              d="M809.4,581.97c0,14.25-27.54,25.8-61.52,25.8c-33.98,0-61.52-11.55-61.52-25.8
                 s25.26-10.36,59.24-10.36C779.57,571.61,809.4,567.72,809.4,581.97z"
            />

            {!minimalMode ? (
              <g>
                <Hair
                  minimalMode={minimalMode}
                  d="M878.6,599.7c0,0,3.8-4,3.8-47.2c0-43.2-4.5-51.4-4.5-51.4s-7.4,59.3-36.2,59.3c-28.8,0-33-14.2-55.8-14.2
		c-22.8,0-33,10.7-33,10.7s-8-9-28.8-9c-20.8,0-28.5,14-60.2,14c-31.7,0-38.4-80.7-38.4-80.7s-6.6,14.1-6.6,47
		c0,32.9,9.8,43.1,9.8,61.5c0,18.3,1.5,38.2,14.1,50.8c0-1.7-0.1-4.9-0.4-6.8c-0.2-1.2-0.8-3.9-0.8-3.9s0.8-2,1.4-3.5
		c0.4-1.2,2.3,0.6,2.5,2.2c0.2,1.7,0.5,3.5,0.7,5.3c3.3,21.9,11.6,51,32.6,51c-7.9-7.9-1-11.7-0.1-12.2c-0.7,0.4-4.8,3.8,11.5,16.1
		c17.6,13.3,19.3,18.5,21.1,30.9c10.6-9.6,8.2-20,8.2-20s15,8.4,18.4,23.9c8.6-0.3,12.6-23.5,12.6-23.5s9.7,11.6,5.4,23.5
		c16.7,3.9,28.6-21.5,28.6-21.5l4.5,18l20.7-19.6l17.3-16.4c0,0,3.3,19.9,11.9,11.3c-4.8-6.3,0.5-8.3,9.1-30.8
		c7,11.3,9,11.3,15.1,11.3c-14.1-6.6,2.6-25.2,0-35.9c3-2.2,3.8,5.6,6.7,7c2-18,14.5-38.9,5.1-49.3
		C876.6,594.6,878.6,599.7,878.6,599.7z M752.9,618.4c-83.4,0-73.4-45.6-70.5-48.5c2.9-2.9,16.1-9.1,30.8-9.1
		c14.6,0,33.5,4.2,39.8,4.2c6.3,0,27.1-4.1,39-4.1c11.9,0,21.9,4.9,24.3,7.3C818.7,570.6,830.8,618.4,752.9,618.4z"
                />
                <Hair
                  minimalMode={minimalMode}
                  d="M620.9,405.9c0,12.1-8.5,7.8-8.5,36s13.1,38.7,13.1,48.8c4.9,0,3.7-41.8,11-37.5s16.4-2.3,16.4-2.3s-18.3-11.7-8.9-21.1
		c4-4,15.2-12.8,23.3-12.8c8.1,0,27.8-12.2,24.4-6c-22.8,20.3-16.2,39-7,40c-7.3-19.9,15.8-38.2,25.3-35.6
		c35,15.1,68.7-11.9,75.6-11.9s12.3,9.8,12.3,9.8s-4.5-9.8,0.5-9.8c35.3,0,29.2,19.4,39.2,29.3c54.4,54.4,25.9,88.4,40.3,74
		c26.2-26.2,6.5-57.5,6.5-57.5s15-10.6,15-21.1s-20.8-38.6,0-59.5c-19.1,0-21.6,10.6-21.6,10.6s10.8-27.6-10.9-49.3
		c9,19.5,3.9,32.4,3.9,32.4S862,330,837.2,330s-41.9,7.4-30.9-26.6c-30.9,12.9-23.9,36.8-23.9,36.8s-17-32.9-48.5-32.9
		c-17.1,0-30.7,14.9-47.5,17.6s-33.5-6.3-33.5-6.3s4.6,12.5,10.6,18.8c6,6.3,16.2,7.1,16.2,7.1s3.9,13.3-31.3,13.3
		s-8.1-44.4-25.5-27s7.5,37.6-5.2,37.6c-17.6,0-20.9-22-20.9-11.3C596.8,403.4,620.9,393.8,620.9,405.9z"
                />
              </g>
            ) : null}
          </animated.g>
        </g>
      </animated.g>
    </svg>
  );
};

export default WoahYouGoBigGuy;
